import '../../assets/scss/MobileMenu.scss';

import { Button, Drawer, Menu } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Header } from 'antd/es/layout/layout';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import useActiveMenuItems from '../../hooks/useActiveMenuItems';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

const MobileMenu = () => {

    const location = useLocation();
    const { pathname } = location;


    const [open, setOpen] = useState<boolean>(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const menuItems: any = useActiveMenuItems();
    

    // const items: MenuProps['items'] = [];

    // if (isAuthenticated) {
    //     features.filter((f: Feature) => f.showSideBar).map((feature) => {
    //         const isDisabled: boolean = (feature.enabled) ? (feature.role.length === 0) ? false : !feature.role.every((featureRole: any) => roles.includes(featureRole)) : true;
    //         items.push({
    //             label: (isDisabled) ? feature.title : <NavLink to={feature.link}>{feature.title}</NavLink>,
    //             key: feature.link.toString(),
    //             icon: feature.icon,
    //             disabled: isDisabled
    //         });
    //         return null;
    //     });
    // }


    // // Check if user is authenticated and is Namespace Admin
    // if (isAuthenticated && roles.some((role: string) => ['nsadmin_home'].includes(role))) {
    //     //adds the Namespace Admin menu depending on the user role
    //     items.push(
    //         { label: <Divider className='.ant-menu-item' orientation="left"><h3>Namespace Management</h3></Divider>, key: 'sideNM' },
    //         // { label: <NavLink to="/nsadmin">My Namespaces</NavLink>, key: '/nsadmin', icon: <BankOutlined /> },
    //     );
    // }
    // if (isAuthenticated && roles.some((role: string) => ["nsadmin_packages"].includes(role))) {
    //     items.push(
    //         { label: <NavLink to="/nsadmin/packages">My Packages</NavLink>, key: '/nsadmin/packages', icon: <AppstoreOutlined /> },
    //     );
    // }

    // if (isAuthenticated && roles.some((role: string) => ["nsadmin_users"].includes(role))) {
    //     items.push(
    //         { label: <NavLink to="/nsadmin/users">My Users</NavLink>, key: '/nsadmin/users', icon: <UsergroupAddOutlined /> },
    //     );
    // }

    // if (isAuthenticated && roles.some((role: string) => ["nsadmin_service_accounts"].includes(role))) {
    //     items.push(
    //         { label: <NavLink to="/nsadmin/service-accounts">My Service Accounts</NavLink>, key: '5', icon: <ApiOutlined /> },
    //     );
    // }

    return (
        <Header className='mobileMenu'>
            <Button onClick={showDrawer} className='mobileMenuButton'><FontAwesomeIcon icon={faBars} /></Button>
            <Drawer className="mobileMenuDrawer"
                placement="left"
                width={500}
                onClose={onClose}
                open={open}
                style={{paddingTop: '75px'
                }}
                height={'100%'}
            >
                <Menu
                    defaultSelectedKeys={[pathname]} selectedKeys={[pathname]}
                    style={{ width: '100%', height: 'max-content', backgroundColor: 'white'}}
                    items={menuItems}
                    onSelect={onClose}
                />

            </Drawer>
        </Header>
    );
};
export default MobileMenu;