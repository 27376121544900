import { Button, Card, Col, Divider, Result, Row } from 'antd'
import { SecureWithLogin, useOidcAccessToken } from '../../utils/secure'
import { useContext, useState } from 'react'

import { Content } from 'antd/es/layout/layout'
import { EnvContext } from '../../contexts'
import { HomeOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import MotionBox from '../Layout/Animated/MotionBox'
import { PageHeader } from '../Layout/header/PageHeader'
import TryItFormV2 from './TryItFormV2'
import { getAvailableCollectionByProduct } from '../../utils/getCollections'

const TryItHomeV2 = () => {

    const defaultRequestCode:any = {
        options: {},
        records: [],
    };

    const [requestCode, setRequestCode] = useState<any>(defaultRequestCode);
    const [responseCode, setResponseCode] = useState<any>();
    const env: string = useContext(EnvContext);
    const { accessTokenPayload } = useOidcAccessToken();
    const [loading, setLoading] = useState<boolean>(false);
    
    // const availableCollections = useMemo(() => getAvailableCollectionByProduct('JETSCAN', env, accessTokenPayload), [env, accessTokenPayload]);

    console.log('rc', requestCode);

    const resetForm = () => {
        setRequestCode(defaultRequestCode);
    };

    return (
        <SecureWithLogin role={['portal_doc', 'js_screen']} callbackPath="/tryIt">
            <MotionBox>
                <Content>
                    {/* Page Header */}
                    <PageHeader
                        title='Try the API'
                        subtitle='Dashboard'
                        breadcrumbs={[
                            { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                            { title: <span>Try the API</span> }]
                        }
                        withEnvSelector
                    />
                    {/* <Divider orientation="left">API documentation</Divider> */}
                    <p style={{ textAlign: "center" }}>Go to the API documentation{' '}<Link to="/doc/">API documentation</Link>{' '}to learn more about the API and how to use it.</p>
                    <Divider/>
                    {/* <Divider orientation="left">Your query to the API</Divider> */}
                    {getAvailableCollectionByProduct('JETSCAN', env, accessTokenPayload).includes('SANCTIONS') ?
                        <>
                        {/* <Row gutter={[24,24]} style={{maxWidth:'1280px', margin:'0 auto'}}> */}
                        <Row gutter={[24,24]} >
                            
                            <Col xs={24}>
                            <TryItFormV2 requestCode={requestCode} setRequestCode={setRequestCode} responseCode={responseCode} setResponseCode={setResponseCode} loading={loading} setLoading={setLoading} resetForm={resetForm} defaultRequestCode={defaultRequestCode}/>
                            </Col>
                            
                        </Row>
                        </>
                        :
                        <Card>
                            <Result
                                status="error"
                                title="You need to have access to the SANCTIONS collection on this environment to use this feature."
                                subTitle="Try changing your environment using the Environment Selector on top of your screen of contact your administrator."
                                extra={
                                    <Link to="/">

                                        <Button type="primary" key="home">
                                            Back to homepage
                                        </Button>
                                    </Link>
                                }
                            />
                        </Card>
                    }
                </Content>
            </MotionBox>
        </SecureWithLogin >
    )
}
export default TryItHomeV2
