import { Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { getEfficiencyScore } from '../utils/mvCalculation';

const { Column } = Table;
interface StatsTableProps {
    mergedData: any;
    options: any;
    runIndex: number;
    currentTabDetailedCharts: string;
}

function StatsTable(props: StatsTableProps) {
    const [statsHit, setStatsHit] = useState<any>(null);
    const [statsNoHit, setStatsNoHit] = useState<any>(null);

    useEffect(() => {
        const buildStats = (mergedData: any) => {
            //For Array display
            let stats_hit: Array<any> = [];
            let stats_nohit: Array<any> = [];
            mergedData.forEach((res: any, n: number) => {

                /**
                 * Checks if the record has metadata
                 * props : expected, category
                 */
                if (res?.modelValidation === undefined || res?.query?.modelValidation?.expected === undefined || res.query?.modelValidation?.category === undefined) {
                    return;
                }

                //depending on the currentDisplay, process stats !== because of 1 component and display
                let stats: any = null;
                if (props.currentTabDetailedCharts === 'effectiveness' && (res.query.modelValidation.expected === "hit" || res.query.modelValidation.expected === "nohit")) {
                    stats = stats_hit;
                }
                if (props.currentTabDetailedCharts === 'efficiency') {// && res.modelValidation.matchesNok.length) {
                    stats = stats_nohit;
                }
                if (stats !== null) {
                    let element = stats.find(function (el: any) {
                        return el.category === res.query.modelValidation.category;
                    });

                    if (element === undefined) {
                        stats.push({
                            "category": res.query.modelValidation.category,
                            "passedCount": 0,
                            "failedCount": 0,
                            "skippedCount": 0,
                            "totalCount": 0,
                            "perfectMatchCount": 0,
                            "highMatchCount": 0,
                            "mediumMatchCount": 0,
                            "lowMatchCount": 0,
                        })
                        element = stats.at(-1);
                    }
                    const status = res.modelValidation.status;

                    if (element !== undefined) {
                        element[status + "Count"]++;
                        element["perfectMatchCount"] += res.modelValidation.efficiencyMap.perfect;
                        element["highMatchCount"] += res.modelValidation.efficiencyMap.high;
                        element["mediumMatchCount"] += res.modelValidation.efficiencyMap.medium;
                        element["lowMatchCount"] += res.modelValidation.efficiencyMap.low;
                        element['totalCount']++;
                    }
                }
            })
            if (stats_hit.length) {
                setStatsHit(stats_hit);
            }
            if (stats_nohit.length) {
                setStatsNoHit(stats_nohit);
            }
        }
        buildStats(props.mergedData);
    }, [props.mergedData, props.currentTabDetailedCharts])

    return (
        <>
            {(statsHit || statsNoHit) &&
                <>
                    {(statsHit && props.currentTabDetailedCharts === "effectiveness") &&
                        <Table dataSource={statsHit} size="small" pagination={false}>
                            <Column title="Category" dataIndex="category" key="category" />
                            <Column title="Total" dataIndex="totalCount" key="totalCount" />
                            <Column title="Passed" dataIndex="passedCount" key="passedCount" />
                            <Column title="Failed" dataIndex="failedCount" key="failedCount" />
                            <Column title="Skipped" dataIndex="skippedCount" key="skippedCount" />
                            <Column title={<Tooltip title="The percentage of records that passed compared to the total records">Success <InfoCircleOutlined /></Tooltip>} key="percent" render={(value: any) => <>{Math.round((value.passedCount / value.totalCount) * 100)}%</>} />
                        </Table>
                    }

                    <div className="statsTable">
                        {(statsNoHit && props.currentTabDetailedCharts === "efficiency") &&
                            <Table dataSource={statsNoHit} size="small" pagination={false} scroll={{ x: 'max-content' }}>
                                <Column title="Category" dataIndex="category" key="category" />
                                <Column title="Hits" key="hits" render={(value: any) => <>{value.perfectMatchCount + value.highMatchCount + value.mediumMatchCount + value.lowMatchCount}</>} />
                                <Column title="Perfect" dataIndex="perfectMatchCount" key="perfectMatchCount" />
                                <Column title="High" dataIndex="highMatchCount" key="highMatchCount" />
                                <Column title="Medium" dataIndex="mediumMatchCount" key="mediumMatchCount" />
                                <Column title="Low" dataIndex="lowMatchCount" key="lowMatchCount" />
                            <Column title="Score" key="percent" render={(value: any) => <>{getEfficiencyScore(value.perfectMatchCount, value.highMatchCount, value.mediumMatchCount, value.lowMatchCount)}</>} />
                            </Table>
                        }
                    </div>

                </>
            }
        </>
    );
}
export default StatsTable;