import { Button, Card, Flex, Result, Tabs, TabsProps, Typography } from 'antd'

import { Content } from 'antd/es/layout/layout'
import { EnvContext } from '../../contexts'
import { HomeOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import MotionBox from '../Layout/Animated/MotionBox'
import { PageHeader } from '../Layout/header/PageHeader'
import { SecureWithLogin } from '../../utils/secure'
import TryItDeveloper from './TryItDeveloper'
import TryItForm from './TryItForm'
import { getAvailableCollectionByProduct } from '../../utils/getCollections'
import { useContext } from 'react'
import { useOidcAccessToken } from './../../utils/secure';

export default function TryItHome() {
    const env: string = useContext(EnvContext);
    const { accessTokenPayload } = useOidcAccessToken();
    console.log(getAvailableCollectionByProduct('JETSCAN', env, accessTokenPayload));
    const tabs: TabsProps['items'] = [
        {
            key: '1',
            label: <Typography.Title level={5} style={{ fontWeight: "normal" }}>I'm a Compliance team member</Typography.Title>,
            forceRender: true,
            children: <TryItForm />,
        },
        {
            key: '2',
            label: <Typography.Title level={5} style={{ fontWeight: "normal" }}>I'm a Developer</Typography.Title>,
            forceRender: true,
            children: <TryItDeveloper />,
        }
    ];
    return (
        <SecureWithLogin role={['portal_doc', 'js_screen']} callbackPath='/try-the-api'>
            <MotionBox>
                <Content>
                    <PageHeader
                        title='Try the API'
                        subtitle='Dashboard'
                        breadcrumbs={[
                            { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                            { title: <span>Try the API</span> }]
                        }
                        withEnvSelector
                    />
                    {getAvailableCollectionByProduct('JETSCAN', env, accessTokenPayload).includes('SANCTIONS') ?
                        <Card type="inner">
                            <Flex vertical gap="large">
                                <Tabs
                                    defaultActiveKey="1"
                                    items={tabs}
                                    size='large'
                                    centered
                                />
                            </Flex>

                        </Card>

                        :
                        <Card>
                            <Result
                                status="error"
                                title="You need to have access to the SANCTIONS collection on this environment to use this feature."
                                subTitle="Try changing your environment using the Environment Selector on top of your screen of contact your administrator."
                                extra={
                                    <Link to="/">

                                        <Button type="primary" key="home">
                                            Back to homepage
                                        </Button>
                                    </Link>
                                }
                            />
                        </Card>
                    }
                </Content>
            </MotionBox>
        </SecureWithLogin >
    )
}