import { Alert, Card, Descriptions, Flex, Space, Table, Tag, notification } from "antd";
import { useEffect, useState } from "react";

import LoadingSkeleton from "../../../Layout/LoadingSkeleton";
import Moment from "react-moment";
import MotionBox from "../../../Layout/Animated/MotionBox";
import NSAPackagesUsersAssign from "./NSAPackagesUsersAssign";
import { SecureWithLogin } from "../../../../utils/secure";
import ShowError from "../../../Layout/ShowError";
import StatusPills from "../../../crm/common/StatusPills";
import VolumeProgress from "../../../Layout/VolumeProgress";
import { callBackEnd } from "../../../../utils/backEndUtils";
import { getAsyncPackageTypes } from "../../../../utils/getPackageTypes";
import useAllEnvironments from "../../../../hooks/useAllEnvironments";
import useAllProducts from "../../../../hooks/useAllProducts";
import { useParams } from "react-router-dom";

export default function NSAPackage() {
    const { namespaceId, packageId } = useParams();
    const { allEnv } = useAllEnvironments();
    const [loadingPackage, setLoadingPackage] = useState<boolean>(true);
    const [errorPackage, setErrorPackage] = useState<any>(null);
    const [pkg, setPkg] = useState<Package | null>(null);
    const { products } = useAllProducts();
    const [staticPackageTypes, setStaticPackageTypes] = useState<StaticPackageType[] | null>(null);

    useEffect(() => {
        async function getPackage() {
            try {
                setLoadingPackage(true);
                const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/packages/${packageId}`);
                setPkg(response);
            } catch (error: any) {
                setErrorPackage(error);
            } finally {
                setLoadingPackage(false);
            }
        }
        async function getStaticPackageTypes() {

            try {
                const packageTypes: any = await getAsyncPackageTypes();
                if (packageTypes?.length > 0) {
                    setStaticPackageTypes(packageTypes);
                }
            } catch (error: any) {

                notification.error({ message: 'An error occured while loading package types' });
            }
        }
        getStaticPackageTypes().catch((error) => { console.log(error) });
        getPackage().catch((e: any) => console.log(e));
    }, [namespaceId, packageId]);

    const renderOptions = (options: any) => {
        if (options.length > 0) {
            return (
                <ul>
                    {options.map((o: any, index: number) => {
                        return (
                            <li key={index}>
                                {o.name}
                                {/* <td>{(o.enabled) ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faBan} />}</td> */}

                                {o.limits !== null &&
                                    <>
                                        {' > '}
                                        {
                                            Object.keys(o.limits).map((k: any, index: number) => {
                                                return (<small key={k.name}>
                                                    {k}
                                                    :{' '}
                                                    {o?.limits[k]}
                                                </small>)
                                            })
                                        }
                                    </>
                                }
                            </li>
                        )
                    })}
                </ul>

            )
        }
        return "No options";
    }

    return (
        <SecureWithLogin callbackPath="/nsadmin" role={['nsadmin_packages']}>
            <MotionBox>
                {pkg && !loadingPackage && errorPackage === null && staticPackageTypes ?
                    <Flex vertical gap="middle">
                        <Card title={<>Package: {pkg?.name}</>}>
                            <Descriptions>
                                <Descriptions.Item label="Status"><Space size="small"><StatusPills type={pkg?.status} /> {pkg?.status}</Space></Descriptions.Item>
                                {pkg?.description && pkg?.description.length && <Descriptions.Item label="Description">{pkg?.description}</Descriptions.Item>}
                                <Descriptions.Item label="Environment">
                                    <Tag color={allEnv.find((e: any) => e.name === pkg.environment)?.color}>{pkg.environment}</Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label="Options">{renderOptions(pkg?.options)}</Descriptions.Item>
                                <Descriptions.Item label="Type">
                                    <Tag color={"blue"}>
                                        {staticPackageTypes.find((t: StaticPackageType) => t.id === pkg.type)?.label.toUpperCase()}
                                    </Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label="Created At"><Moment format="YYYY-MM-DD hh:mm:ss">{pkg?.created}</Moment></Descriptions.Item>
                                <Descriptions.Item label="Updated At"><Moment format="YYYY-MM-DD hh:mm:ss">{pkg?.updated}</Moment></Descriptions.Item>
                            </Descriptions>
                        </Card>
                        <Card title={<>Package volumes</>}>
                            <Table
                                dataSource={pkg.volumes}
                                columns={[
                                    {
                                        title: 'Engine',
                                        dataIndex: 'product',
                                        key: 'product',
                                        render: (product: string) => {
                                            const p = products.find((p: Product) => p?.value.toLowerCase() === product?.toLowerCase());
                                            return (
                                                <Tag color={p?.color}>{product}</Tag>
                                            )
                                        }
                                    },
                                    {
                                        title: 'Collection',
                                        dataIndex: 'collection',
                                        key: 'collection'
                                    },
                                    {
                                        title: 'Amount',
                                        dataIndex: 'amount',
                                        key: 'amount'
                                    },
                                    {
                                        title: 'Cycle',
                                        dataIndex: 'cycle',
                                        key: 'cycle'
                                    },
                                    {
                                        title: 'Options',
                                        dataIndex: 'options',
                                        key: 'options',
                                        render: (o: any) => {
                                            if (o === null) {
                                                return "None"
                                            }
                                            return o?.join(', ')
                                        }
                                    },
                                    {
                                        title: 'Usage',
                                        key: 'usage',
                                        render: (v: any) => {
                                            return <VolumeProgress pack={pkg} volume={v} type="line" />
                                        }
                                    }
                                ]}
                                rowKey="collection"
                                pagination={false}
                            />
                        </Card>
                        {pkg && Number(pkg.type) !== 3 ?
                            <NSAPackagesUsersAssign namespaceId={namespaceId} pkg={pkg} />
                        :
                            <Alert message="This type of package can not be assigned to users. It can only be used with service accounts." type="info" showIcon />
                        }
                    </Flex>
                    :
                    <>
                        {loadingPackage && <LoadingSkeleton />}
                        {errorPackage && <ShowError error={errorPackage} />}
                    </>
                }

            </MotionBox>
        </SecureWithLogin>

    )
}
