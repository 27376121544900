import { Button, Flex, Table, Tag, Typography, notification } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';

export default function MatchCompareTable(props: { runsData: any }) {

    const { runsData } = props;

    const [formattedData, setFormattedData] = useState<any>([]);

    const compareMatchs = (m1: any, m2: any) => {
        if (m1 && m2 && m1.type === m2.type && m1.profileId === m2.profileId && m1?.location?.path === m2?.location?.path && m1?.location?.from === m2?.location?.from && m1?.location?.to === m2?.location?.to) {
            return true;
        }
        return false;
    }

    const getRecordFromRun = useCallback((runNumber: number, recordId: number, match: any) => {
        return runsData[runNumber].mergedData.find((record: any) => record._index === recordId);
    }, [runsData]);

    const getMatchResult = useCallback((m: any, d: any) => {
        let result: string = '';
        if (d.query.modelValidation.expected !== 'hit') {
            result = "Unexpected Match";
        }
        else if (d.query.modelValidation.locationPath === m.location.path && d.query.modelValidation.profileId === m.profileId) {
            result = "Expected Match";
        } else if (d.query.modelValidation.locationPath !== m.location.path && d.query.modelValidation.profileId === m.profileId) {
            result = "Extra - Other location path";
        } else if (d.query.modelValidation.locationPath === m.location.path && d.query.modelValidation.profileId !== m.profileId) {
            result = "Extra - Other profile id";
        }
        return result;
    }, []);

    // const dataForTable = () => {
    //     let matches: any = [];
    //     formattedData.forEach((record: any) => {
    //         record.processedMatches.forEach((match: any) => {

    //             let element2push: any = {
    //                 match,
    //                 record: record.record,
    //                 recordIndex: record.recordIndex,
    //             };
    //             let statusObject: any = {};
    //             match.runs.forEach((runNumber: number) => {
    //                 statusObject['statusRun' + runNumber] = getMatchResult(match.match, getRecordFromRun(runNumber, record.record._index, match));
    //             });
    //             //insert missingStatus
    //             const nbRuns: number = runsData.length;
    //             for (let i = 0; i < nbRuns; i++) {
    //                 if (!statusObject['statusRun' + i]) {
    //                     statusObject['statusRun' + i] = "No match";
    //                 }
    //             }
    //             // const status: any[] = match.runs.map((runNumber: number) => {
    //             //     return {
    //             //         run: runNumber,
    //             //         message: getMatchResult(match.match, getRecordFromRun(runNumber, record.record._index, match))
    //             //     };
    //             // });
    //             // const nbRuns:number = runsData.length;
    //             // if(status.length < nbRuns){
    //             //     for(let i = 0; i < nbRuns; i++){
    //             //         if(status.findIndex((s:any) => s.run === i) === -1){
    //             //             status.push({
    //             //                 run: i,
    //             //                 message: "No match"
    //             //             });
    //             //         }
    //             //     }
    //             // }

    //             Object.assign(element2push, statusObject);
    //             matches.push(element2push);
    //         });
    //     });
    //     return matches;
    // }

    useEffect(() => {

        const data: any = [];

        runsData.forEach((run: any, index: number) => {
            run.mergedData.forEach((record: any) => {
                const matchIndex = data.findIndex((match: any) => match.recordIndex === record._index);
                if (matchIndex > -1) {
                    data[matchIndex].matchesByRun.push(record.matches);
                    return;
                } else {

                    data.push({
                        matchesByRun: [record.matches],
                        record,
                        recordIndex: record._index,
                        processedMatches: []
                    });
                }
            });
        });

        data.forEach((item: any) => {
            const processedMatches: any = [];

            item.matchesByRun.forEach((matches: any, runIndex: number) => {
                matches.forEach((match: any) => {
                    const existingMatchIndex = processedMatches.findIndex((processedMatch: any) => compareMatchs(processedMatch.match, match));

                    if (existingMatchIndex > -1) {
                        processedMatches[existingMatchIndex].runs.push(runIndex);
                    } else {
                        processedMatches.push({ match, runs: [runIndex] });
                    }
                });
            });

            item.processedMatches = processedMatches;
        });

        let matches: any = [];
        data.forEach((record: any) => {
            record.processedMatches.forEach((match: any) => {

                let element2push: any = {
                    match,
                    record: record.record,
                    recordIndex: record.recordIndex,
                };

                let statusObject: any = {};
                match.runs.forEach((runNumber: number) => {
                    statusObject['statusRun' + runNumber] = getMatchResult(
                        match.match,
                        getRecordFromRun(runNumber, record.record._index, match)
                    );
                });

                // Insert missing status
                const nbRuns: number = runsData.length;
                for (let i = 0; i < nbRuns; i++) {
                    if (!statusObject['statusRun' + i]) {
                        statusObject['statusRun' + i] = "No match";
                    }
                }

                Object.assign(element2push, statusObject);
                matches.push(element2push);
            });
        });

        setFormattedData(matches);
    }, [runsData, getMatchResult, getRecordFromRun]);



    const getColumns = () => {

        let columns = [
            {
                title: 'Reference',
                dataIndex: 'record',
                key: 'reference',
                render: (record: any) => {
                    // return <Typography.Text copyable={{ text: JSON.stringify(record?.query), tooltips: [<>Copy payload to clipboard</>, <>Copied to clipboard</>] }}>{record?.query?.modelValidation?.reference}</Typography.Text>
                    return record?.query?.modelValidation?.reference;
                }
            },
            {
                title: 'Category',
                dataIndex: 'record',
                key: 'category',
                render: (record: any) => {
                    return record?.query?.modelValidation?.category
                }
            },
            {
                title: 'Screened',
                dataIndex: 'match',
                key: 'screened',
                render: (match: any) => {
                    return match?.match?.screened
                }
            },
            {
                title: 'Matched',
                dataIndex: 'match',
                key: 'matched',
                render: (match: any) => {
                    return match?.match?.match
                }
            },
            {
                title: 'Expected ID',
                dataIndex: 'record',
                key: 'expectedId',
                render: (record: any) => {
                    return record?.query?.modelValidation?.profileId
                }
            },
            {
                title: 'Matched ID',
                dataIndex: 'match',
                key: 'matchedId',
                render: (match: any) => {
                    return match?.match?.profileId
                }
            },
            {
                title: 'Location Path',
                dataIndex: 'match',
                key: 'locationPath',
                render: (match: any) => {
                    return match?.match?.location?.path
                }
            },
            {
                title: 'Match Type',
                dataIndex: 'match',
                key: 'matchType',
                render: (match: any) => {
                    return match?.match?.type
                }
            },
            {
                title: 'Score',
                dataIndex: 'match',
                key: 'score',
                render: (match: any) => {
                    return match?.match?.score
                }
            },
            {
                title: 'Expected Result',
                dataIndex: 'record',
                key: 'expectedResult',
                render: (record: any) => {
                    return record?.query?.modelValidation?.expected.toUpperCase()
                }
            },
            ...runsData.map((run: any, index: number) => {
                return {
                    title: `Run ${index + 1}`,
                    dataIndex: `statusRun${index}`,
                    key: `statusRun${index}`,
                    render: (status: string) => {
                        const colorAndIcon: any = () => {
                            if (status?.includes('Expected')) {
                                return {
                                    color: 'green',
                                    icon: <CheckCircleOutlined />
                                }
                            }
                            else if (status?.includes('Unexpected')) {
                                return {
                                    color: 'red',
                                    icon: <CloseCircleOutlined />
                                }
                            }
                            else {
                                return {
                                    color: 'orange',
                                    icon: <ExclamationCircleOutlined />
                                }
                            }
                        }
                        return (
                            <Tag bordered={false} icon={colorAndIcon().icon} color={colorAndIcon().color}>
                                {status?.toUpperCase()}
                            </Tag>
                        )
                    }
                }
            }),
            {
                title: 'Actions',
                dataIndex: 'record',
                key: 'actions',
                render: (record: any) => {
                    return (<Button
                        type="link"
                        onClick={() => {
                            navigator.clipboard.writeText(JSON.stringify(record?.query))
                            notification.success({ message: "Copied to clipboard" });
                        }}>
                        Copy query to clipboard
                    </Button>)
                }
            }

        ]


        return columns;
    }

    const handleExportCSV = (data: any) => {
        const csv = data.map((d: any) => {

            const runStatuses = runsData.reduce((acc: any, run: any, index: number) => {
                acc[`Run ${index + 1}`] = d[`statusRun${index}`];
                return acc;
            }, {});

            return {
                Reference: d?.record?.query?.modelValidation?.reference,
                Category: d?.record?.query?.modelValidation?.category,
                Screened: d?.match?.match?.screened,
                Matched: d?.match?.match?.match,
                ExpectedId: d?.record?.query?.modelValidation?.profileId,
                MatchedId: d?.match?.match?.profileId,
                LocationPath: d?.match?.match?.location?.path,
                MatchType: d?.match?.match?.type,
                Score: d?.match?.match?.score,
                ExpectedResult: d?.record?.query?.modelValidation?.expected.toUpperCase(),
                ...runStatuses
            }
        });

        const now = new Date();
        const datetime = now.toISOString().replace(/:/g, '-');

        const header = Object.keys(csv[0]);
        const csvData = csv.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName])).join('\t'));
        const csvString = [header.join('\t'), ...csvData].join('\r\n');

        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `modelValidationRunComparaison_${datetime}.csv`;
        a.click();

    }

    return (
        <>
            {formattedData.length > 0 &&
                <>
                    <Flex
                        justify="space-between"
                        align="center"
                    >
                        <Typography.Title level={5}>Run Comparaison Details</Typography.Title>
                        <Button type="link" onClick={() => handleExportCSV(formattedData)}>Export to CSV</Button>

                    </Flex>

                    <Table
                        size='small'
                        scroll={{ x: 'max-content' }}
                        dataSource={formattedData}
                        rowKey={(record: any) => {
                            return record.match.match.matchId
                        }}
                        columns={getColumns()}
                        pagination={false}
                    />
                </>
            }
        </>

    )
}
