import { Button, Table, Tag, Tooltip } from 'antd';
import { faArchive, faCheck, faLock, faPenToSquare, faPlay, faStop, faTimes } from '@fortawesome/free-solid-svg-icons';

import CustomModal from '../../../../crm/common/CustomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import SAPackageInfos from './SAPackageInfos';
import { callBackEnd } from '../../../../../utils/backEndUtils';
import dayjs from 'dayjs';
import { toTitleCase } from '../../../../../utils/stringUtils';
import { useState } from 'react';

export const ServiceAccountsTable = (props: any) => {
    
    const { serviceAccountSource, namespaceId } = props;
    const [editedServiceAccount, setEditedServiceAccount] = useState<ServiceAccount | null>(serviceAccountSource);
    console.log("serviceAccountSource", serviceAccountSource);
    const showSAIcons = (status: ServiceAccountStatus) => {
        let icon: any = null;
        switch (status) {
            case "ACTIVE":
                icon = faCheck;
                break;
            case "INACTIVE":
                icon = faTimes;
                break;
            case "ARCHIVED":
                icon = faArchive;
                break;
        }

        return (
            <FontAwesomeIcon icon={icon} />
        )
    }
    const [showActivateModal, setShowActivateModal] = useState<boolean>(false);
    const [showDeactivateModal, setShowDeactivateModal] = useState<boolean>(false);
    const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);


    //Modals
    const handleActivateModalResponse = (response: boolean) => {
        if (editedServiceAccount?.uid !== undefined) {
            if (response) {
                setShowActivateModal(false);
                handleChangeStatus(editedServiceAccount?.uid, 'ACTIVE');
            }
            else {
                setShowActivateModal(false);
            }
        }
    }
    const handleDeactivateModalResponse = (response: boolean) => {
        if (editedServiceAccount?.uid !== undefined) {
            if (response) {
                setShowDeactivateModal(false);
                handleChangeStatus(editedServiceAccount?.uid, 'INACTIVE');
            }
            else {
                setShowDeactivateModal(false);
            }
        }
    }
    const handleArchiveModalResponse = (response: boolean) => {
        if (editedServiceAccount?.uid !== undefined) {
            if (response) {
                setShowArchiveModal(false);
                handleChangeStatus(editedServiceAccount?.uid, 'ARCHIVED');
            }
            else {
                setShowArchiveModal(false);
            }
        }
    }
    //hidden until releasing the feature
    // const handleDuplicateSa = async (uid: string) => {
    //     try {
    //         await callBackEnd('portal', 'post', `namespaces/${namespaceId}/service-accounts/${uid}/clone`);
    //     } catch (error: any) {
    //         console.log(error);
    //     }
    //     finally {
    //         props.getServiceAccounts().catch((error: any) => { console.log(error) });
    //     }
    // }

    const handleChangeStatus = async (uid: string, status: string) => {
        try {
            await callBackEnd('portal', 'put', `namespaces/${namespaceId}/service-accounts/${uid}/status/${status}`);
        } catch (error: any) {
            console.log(error);
        }
        finally {
            props.getServiceAccounts().catch((error: any) => { console.log(error) });
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Authentication Type',
            dataIndex: 'authenticationType',
            key: 'authenticationType',
            render: (authenticationType: string, record:any) => (
                // authenticationType ? <Tag color="green">{authenticationType}</Tag> : <Tag color="red">None</Tag>
                authenticationType ? 
                    <Tag color="green">{authenticationType}</Tag>
                :
                // <ShowIfAccess authenticated={true} role={['ns_admin_service_account']}>
                    <Link to={`/nsadmin/${namespaceId}/service-accounts/${record.uid}/authentication`}>
                        <Button type="dashed" icon={<FontAwesomeIcon icon={faLock} />}>
                            Set Authentication
                        </Button>
                    </Link>
                // </ShowIfAccess>
            )
        },
        {
            title: 'Date Created',
            dataIndex: 'created',
            key: 'created',
            render: (created: string) => {
                const date = dayjs(created).format('YYYY/MM/DD');
                return date;
            }
        },
        {
            title: 'Last Update',
            dataIndex: 'updated',
            key: 'updated',
            render: (updated: string) => {
                const date = dayjs(updated).format('YYYY/MM/DD');
                return date;
            }
        },
        {
            title:'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: ServiceAccountStatus, record:any) => {
                return (
                    <div className='status'><span className={`serviceAccountPill ${status.toLowerCase()}`}>
                        {showSAIcons(status)}{' '}{(status === "INACTIVE" && !record.hasOwnProperty('authenticationType')) ?
                            toTitleCase(`${status} (No authentication)`)
                            :
                        toTitleCase(status)
                        }
                    </span></div>
                )
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_:any, record:any) => (
            <>
                <>
                {record?.authenticationType ?
                    <>
                        {(record?.status === "INACTIVE") &&
                            <Tooltip title="Activate">
                            <Button type="text" onClick={(e: any) => {
                                e.preventDefault();
                                setEditedServiceAccount(record)
                                setShowActivateModal(true)
                            }}
                                icon={<FontAwesomeIcon icon={faPlay} />}
                            >
                            </Button>
                            </Tooltip>
                        }
                        {(record?.status === "ACTIVE") &&
                            <Tooltip title="Deactivate">
                            <Button type="text" style={{color:'red'}} onClick={(e: any) => {
                                e.preventDefault();
                                setEditedServiceAccount(record)
                                setShowDeactivateModal(true)
                            }}
                                icon={<FontAwesomeIcon icon={faStop} />}
                            >
                            </Button>
                            </Tooltip>
                        }
                    </>
                    : <Tooltip title="Set authentication to unlock"><Button disabled type="text" icon={<FontAwesomeIcon icon={faLock} />} /></Tooltip>}
            </>
            
                {/* Hidden until decide to release the feature */}
                {/* {(record.status === "ACTIVE") || (record.status === "ARCHIVED") ?
                    <Tooltip title={`Can't duplicate ${record.status} Service Account`} ><Button disabled type="text" key="li k" icon={<FontAwesomeIcon icon={faCopy} />}></Button></Tooltip>
                    :
                    editedServiceAccount && <Tooltip title="Duplicate" ><Button type="text" key="link" icon={<FontAwesomeIcon icon={faCopy} />} onClick={(e: any) => { handleDuplicateSa(editedServiceAccount.uid) }}/></Tooltip>
                } */}

            <>
             {(record.status !== "ARCHIVED") && (record.status === "INACTIVE") ?
             <Link to={`/nsadmin/${namespaceId}/service-accounts/${record.uid}`}>
                <Tooltip title="Edit info"><Button type="text" data-text="Edit info" icon={<FontAwesomeIcon icon={faPenToSquare} />}/></Tooltip>
            </Link>
            :
            <Tooltip title={`Can't edit ${record.status} Service Account`}><Button disabled type="text" data-text="Edit disabled" icon={<FontAwesomeIcon icon={faPenToSquare} />}/></Tooltip>
            }
        </>
            <>{(record.status === "INACTIVE") && <Tooltip title="Archive" ><Button type="text" icon={<FontAwesomeIcon icon={faArchive} />} onClick={(e: any) => {
                        e.preventDefault();
                        setEditedServiceAccount(record)
                        setShowArchiveModal(true)
                    }} /></Tooltip>}</>
        </>
            ),
        }
    ];

    return <div className='serviceAccount'>
            <Table
                expandable={{expandedRowRender: (sa: any) => sa && <SAPackageInfos packageId={sa?.packageId} clientId={sa.clientId}/>}}
                rowKey={(record) => record.uid}
                dataSource={serviceAccountSource}
                columns={columns}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
            {showActivateModal && editedServiceAccount !== null &&
                    <CustomModal secureString={editedServiceAccount?.name} title="Activate Service Account" message={`Are you sure you want to activate the Service Account named "${editedServiceAccount.name}" ? Please type in its name "${editedServiceAccount.name}" to confirm.`} callback={handleActivateModalResponse} />
                }
                {showDeactivateModal && editedServiceAccount !== null &&
                    <CustomModal secureString={editedServiceAccount?.name} title="Deactivate Service Account" message={`Are you sure you want to deactivate the Service Account named "${editedServiceAccount.name}" ? Please type in its name "${editedServiceAccount.name}" to confirm.`} callback={handleDeactivateModalResponse} />
                }
                {showArchiveModal && editedServiceAccount !== null &&
                    <CustomModal secureString={editedServiceAccount?.name} title="Archive Service Account" message={`Are you sure you want to archive the Service Account named "${editedServiceAccount.name}" ? This operation can not be undone. Please type in its name "${editedServiceAccount.name}" to confirm.`} callback={handleArchiveModalResponse} />
                }
        </div>
}
export default ServiceAccountsTable

