import { Button, Card, Descriptions, Divider, Flex, Table, Tooltip, notification } from 'antd';
import { Link, useNavigate, useParams } from "react-router-dom";
import { SecureWithLogin, ShowIfAccess, useOidcUser } from '../../../../utils/secure';
import { faUserLock, faUserSlash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

import CustomModal from '../../../crm/common/CustomModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from '../../../Layout/Loading';
import Moment from 'react-moment';
import NSAUserPackagesAssign from './NSAUserPackagesAssign';
import ShowError from '../../../Layout/ShowError';
import { callBackEnd } from '../../../../utils/backEndUtils';

export default function NSAUser() {

    const { userId, namespaceId } = useParams();
    const navigate = useNavigate();

    const [loadingUser, setLoadingUser] = useState<boolean>(true);
    const [errorUser, setErrorUser] = useState<any>(null);
    const [user, setUser] = useState<UserProfile | null>(null);
    const [loadingRoles, setLoadingRoles] = useState<boolean>(true);
    const [errorRoles, setErrorRoles] = useState<any>(null);
    const [roles, setRoles] = useState<Role[] | null>(null);
    const [rolesDefinition, setRolesDefinition] = useState<any[] | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

    const [namespaceDetails, setNamespaceDetails] = useState<Namespace | null>(null);

    const { oidcUser }: { oidcUser: any } = useOidcUser();
    const isRestricted: boolean = oidcUser?.jetUserId === user?.uid; //user can't edit himself




    useEffect(() => {
        async function getUser() {
            try {
                setLoadingUser(true);
                setLoadingRoles(true);
                const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/users/${userId}`);
                setUser(response);

                if (namespaceId) {
                    //Namespace details
                    const namespaceResponse: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}`).catch((e: any) => console.log(e));
                    setNamespaceDetails(namespaceResponse);
                    //Roles definition
                    const responseRolesDefinition: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/roles`).catch((e: any) => console.log(e));
                    setRolesDefinition(responseRolesDefinition);
                    //Roles
                    const responseRoles: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/users/${userId}/roles`).catch((e: any) => setErrorRoles(e));
                    setRoles(responseRoles.roles);
                    setLoadingRoles(false);
                }

            } catch (error: any) {
                setErrorUser(error);
            } finally {
                setLoadingUser(false);
            }
        }
        getUser().catch((e: any) => console.log(e));
    }, [userId, namespaceId]);

    const handleRemoveModalResponse = (response: boolean) => {
        if (response && !isRestricted) {
            setShowRemoveModal(false);

            async function removeFromNamespace() {
                try {
                    await callBackEnd('portal', 'DELETE', `namespaces/${namespaceId}/users/${userId}`);
                } catch (error: any) {
                    setErrorUser(error);
                } finally {
                    notification.success({ message: `User ${user?.email} has been removed from the namespace` });
                    if (namespaceId) {
                        navigate(`/nsadmin/users`)
                    }
                }
            }
            removeFromNamespace().catch((e: any) => console.log(e));
        }
        else {
            setShowRemoveModal(false);
        }
    }

    const getRoleDescription = (role: string) => {
        if (rolesDefinition) {
            const roleDefinition = rolesDefinition.find((r) => r.name === role);
            return roleDefinition?.description;
        }
        return
    }

    return (
        <SecureWithLogin callbackPath="/nsadmin" role={['nsadmin_users']}>
            {(errorUser === null && !loadingUser && user !== null) ?
                <>
                    <Card
                        title={<>User: {user.email}</>}
                        className="users"
                        extra={
                            <Tooltip title={isRestricted && "You can not edit your own account"}>
                                <Button type="primary" danger onClick={() => setShowRemoveModal(true)} icon={<FontAwesomeIcon icon={faUserSlash}></FontAwesomeIcon>} disabled={isRestricted}>
                                    Remove user from namespace
                                </Button>
                            </Tooltip>
                        }
                    >
                        <Descriptions>
                            <Descriptions.Item label="Firstname">{user?.firstName}</Descriptions.Item>
                            <Descriptions.Item label="Lastname">{user?.lastName}</Descriptions.Item>
                            <Descriptions.Item label="E-Mail">{user?.email}</Descriptions.Item>
                            {namespaceDetails &&
                                <Descriptions.Item label="Namespace">
                                    {namespaceDetails.label} ({namespaceDetails.name})
                                </Descriptions.Item>
                            }
                            <Descriptions.Item label="Last Login"><Moment date={user?.lastLogin} /></Descriptions.Item>
                        </Descriptions>
                        <Divider />
                        <Flex vertical gap="large">

                            <NSAUserPackagesAssign user={user} namespaceId={namespaceId} />

                            {(errorRoles === null && !loadingRoles && roles !== null && rolesDefinition !== null) ?
                                <Card
                                    title="Roles and available features"
                                    type="inner"
                                    extra={
                                        <ShowIfAccess role={['nsadmin_users']}>
                                            <Link to={`/nsadmin/${user.namespaceId}/users/${user.uid}/roles`}>
                                                <Tooltip title={isRestricted && "You can not edit your own account"}>
                                                    <Button type="primary" icon={<FontAwesomeIcon icon={faUserLock}></FontAwesomeIcon>} disabled={isRestricted}>
                                                        Edit user roles
                                                    </Button>
                                                </Tooltip>
                                            </Link>
                                        </ShowIfAccess>
                                    }
                                >
                                    {roles.length > 0 ?
                                        <Table
                                            size='small'
                                            pagination={false}
                                            dataSource={roles}
                                            rowKey="name"
                                            scroll={{ x: 'max-content' }}
                                            columns={[
                                                {
                                                    title: 'Role',
                                                    dataIndex: 'name',
                                                    key: 'name',
                                                    render: (value: string) => {
                                                        return getRoleDescription(value)
                                                    }
                                                },
                                                {
                                                    title: 'Environments',
                                                    dataIndex: 'environments',
                                                    key: 'environments',
                                                    render: (value: string[], r: any) => {
                                                        if (value?.length > 0) {
                                                            return value.join(', ');
                                                        }
                                                        else {
                                                            return 'Feature not environment-related';
                                                        }
                                                    }
                                                }
                                            ]}
                                        />
                                        :
                                        <p>No roles found</p>
                                    }
                                </Card>
                                :
                                <>
                                    {loadingRoles && <Loading />}
                                    {errorRoles && <ShowError error={errorRoles} />}
                                </>

                            }

                        </Flex>
                    </Card>



                    {showRemoveModal && user !== null &&
                        <CustomModal secureString={user.email} title="Remove user from namespace" message={`Are you sure you want to remove the user ${user.email} from this Namespace ? Please type in the name of the user "${user.email}" as a confirmation.`} callback={handleRemoveModalResponse} />
                    }

                </>
                :
                <>
                    {loadingUser && <Loading />}
                    {errorUser && <ShowError error={errorUser} />}
                </>
            }
        </SecureWithLogin >
    )
}
