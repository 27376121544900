import { Avatar, Menu, Popover } from 'antd'
import React, { useEffect, useState } from 'react';
import { faLandmark, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons'
import { useOidc, useOidcUser } from '../../utils/secure';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { MenuProps } from 'antd';
import { NavLink } from 'react-router-dom'
import { useAuthInfo } from '../../utils/secure';
import useUserInfos from '../../hooks/useUserInfos';

const AccountMenu = () => {
    const { oidcUser, }: { oidcUser: any } = useOidcUser();
    const { userInfos } = useUserInfos(oidcUser?.jetUserId);
    const { logout } = useOidc();
    const [isOpen, setIsOpen] = useState(false);
    const { isAuthenticated, roles } = useAuthInfo();

    const handleOpenChange = (open: boolean) => {
        setIsOpen(open);
    };
    useEffect(() => {
        setIsOpen(isOpen);
    }, [isOpen]);

    const items: MenuProps['items'] = [
        {
            label: <NavLink to='/account'><FontAwesomeIcon icon={faUser} style={{ marginRight: '.5rem' }} />My Account</NavLink>,
            key: '/account',
        },
        ...(isAuthenticated && roles.some((role: string) => ['crm_partners', 'crm_namespaces', 'crm_users'].includes(role)) ? [
            {
                label: <><FontAwesomeIcon icon={faLandmark} style={{ marginRight: '.5rem' }} />Access and Enablement</>,
                key: 'accessAndEnablement',
                children: [
                    ...(roles.some((role: any) => ['crm_namespaces'].includes(role)) ? [{ key: '/namespaces', label: <NavLink to='/namespaces'>All namespaces</NavLink> }] : []),
                    ...(roles.some((role: any) => ['crm_users'].includes(role)) ? [{ key: '/users', label: <NavLink to='/users'>All users</NavLink> }] : []),
                ],
            }
        ] : []),
        {
            label: <NavLink to="/" onClick={() => logout()}><FontAwesomeIcon icon={faRightFromBracket} style={{ marginRight: '.5rem' }} />Logout</NavLink>,
            key: '3',
        },
    ];

    const getAvatarName = (oidcUser: any) => {
        if (oidcUser.given_name !== '' && oidcUser.family_name !== '') {
            return oidcUser?.given_name?.charAt(0).toUpperCase() + oidcUser?.family_name?.charAt(0).toUpperCase();
        }
        else {
            return oidcUser?.name?.charAt(0).toUpperCase();
        }
    };

    const getAvatarColor = (userInfos: any) => {
        switch (userInfos?.formattedContent?.status?.type) {
            case 'info':
                return '#1890ff';
            case 'success':
                return '#22c55e';
            case 'warning':
                return '#faad14';
            default:
                return '#22c55e';
        }
    }

    return (
        // <Dropdown menu={{ items }} trigger={['click']} overlayClassName='userMenuDropdown'>
        //     <Link onClick={(e) => e.preventDefault()} to={''}>
        //         <button className='userMenu'>
        //             {oidcUser?.name ?
        //                 <Avatar size={40} style={{ backgroundColor: getAvatarColor(userInfos), verticalAlign: 'middle' }} >
        //                     {getAvatarName(oidcUser)}
        //                 </Avatar>
        //                 :
        //                 <>Loading ...</>
        //             }
        //         </button>
        //     </Link>
        // </Dropdown>
        <Popover
            placement="bottomRight"
            style={{ margin: '24px' }}
            open={isOpen}
            onOpenChange={handleOpenChange}
            trigger="click"
            content={
                <Menu theme="light"
                    className='account-menu'
                    mode="inline"
                    style={{ width: 256, border: 'none' }}
                    defaultOpenKeys={["accessAndEnablement"]}
                    items={items} onClick={() => handleOpenChange(false)} />
            }>
            <button className='userMenu'>
                {oidcUser?.name ?
                    <Avatar size={40} style={{ backgroundColor: getAvatarColor(userInfos), verticalAlign: 'middle' }} >
                        {getAvatarName(oidcUser)}
                    </Avatar>
                    : <>Loading ...</>}
            </button>
        </Popover>

    )
}
export default React.memo(AccountMenu)