import { BarChartOutlined, CloudServerOutlined, CustomerServiceOutlined, FundViewOutlined, HomeOutlined, MailOutlined, PlayCircleOutlined, QuestionCircleOutlined, ReadOutlined, TagOutlined, UnorderedListOutlined } from '@ant-design/icons';

import { useMemo } from 'react';

export const useFeatures = () => {
    const data = useMemo(() =>
        [
            {
                id: 1,
                title: "Home",
                description: "Welcome to the Neterium Dashboard",
                link: "/",
                icon: <HomeOutlined />,
                role: [],
                module: [],
                showSideBar: true,
                enabled: true,
                showQuickStart: false
            },
            {
                id: 2,
                title: "Quick Start Guide",
                description: "Use this section to get started with the Neterium API.",
                link: "/quickstart",
                icon: null,
                role: ['portal_quickstart'],
                module: [],
                showSideBar: false,
                enabled: false,
                showQuickStart: true
            },
            {
                id: 3,
                title: "News",
                description: "News and updates about your Neterium services",
                link: "/news",
                icon: <MailOutlined />,
                role: [],
                module: ['module_news'],
                showSideBar: true,
                enabled: false,
                showQuickStart: true
            },
            {
                id: 4,
                title: "API Documentation",
                description: "Access the full documentation of the Neterium API, including examples that you can use to try the API directly.",
                link: "/doc",
                icon: <ReadOutlined />,
                role: ['portal_doc'],
                module: ['module_api_documentation'],
                showSideBar: true,
                enabled: true,
                showQuickStart: true
            },
            {
                id: 5,
                title: "Try the API",
                description: "Use this section to try the Neterium API directly.",
                link: "/try-the-api",
                icon: <PlayCircleOutlined />,
                role: ['portal_doc', 'js_screen'],
                module: ['module_try_it'],
                showSideBar: true,
                enabled: true,
                showQuickStart: true
            },
            {
                id: 6,
                title: "Release Notes",
                description: "Read about all enhancements and fixes made to each release of the API.",
                link: "/release-notes",
                icon: <TagOutlined />,
                role: ['portal_release_notes'],
                module: ['module_release_notes'],
                showSideBar: true,
                enabled: true,
                showQuickStart: true
            },
            {
                id: 7,
                title: "Service Status",
                description: "Display the current status of all the services and data collections of the Neterium API.",
                link: "/cluster-status",
                icon: <CloudServerOutlined />,
                role: ['portal_cluster_status'],
                module: ['module_service_status'],
                showSideBar: true,
                enabled: true,
                showQuickStart: true
            },
            {
                id: 8,
                title: "List Status",
                description: "Consult the status of each collection, including update times and changes, as well as full list statistics.",
                link: "/list-status",
                icon: <UnorderedListOutlined />,
                role: ['portal_list_status'],
                module: ['module_list_status'],
                showSideBar: true,
                enabled: true,
                showQuickStart: true
            },
            {
                id: 9,
                title: "Statistics",
                description: "Use this section to view the Neterium API usage.",
                link: "/stats",
                icon: <BarChartOutlined />,
                role: ['js_stats'],
                module: ['module_statistics'],
                showSideBar: true,
                enabled: true,
                showQuickStart: true
            },
            {
                id: 10,
                title: 'Model Validation',
                description: 'This module allows you to perform a complete effectiveness and efficiency diagnostic of your screening parameters, and produces a full summary report allowing to compare settings and tune the service to your risk appetite.',
                link: '/model-validation',
                icon: <FundViewOutlined />,
                role: ['portal_model_validation', 'js_screen'],
                module: ['module_model_validation'],
                showSideBar: true,
                enabled: true,
                showQuickStart: true
            },
            {
                id: 11,
                title: 'FAQ',
                description: 'Search the most common questions and issues in using the Neterium screening service.',
                link: '/faq',
                icon: <QuestionCircleOutlined />,
                role: [],
                module: ['module_faq'],
                showSideBar: true,
                enabled: false,
                showQuickStart: true
            },
            {
                id: 12,
                title: 'Support',
                description: 'Get in touch with our support team for any questions or issues you may have.',
                link: '/support',
                icon: <CustomerServiceOutlined />,
                role: ['portal_support'],
                module: ['module_support'],
                showSideBar: true,
                enabled: false,
                showQuickStart: true
            }
        ], []);
    return data;
}