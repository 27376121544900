import { ApiOutlined, AppstoreOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { Divider } from "antd";
import { NavLink } from "react-router-dom";
import { useAuthInfo } from '../utils/secure';
import { useFeatures } from "./useFeatures";
import { useRoles } from "../contexts/RolesContext";

export default function useActiveMenuItems() {
    const rolesCtx = useRoles();
    const { isAuthenticated } = useAuthInfo();
    const features = useFeatures();

    const [activeMenuItems, setActiveMenuItems] = useState([]);


    


    useEffect(() => {
        if (rolesCtx && rolesCtx.roles !== null) {
            const rolesFiltered = rolesCtx.roles.filter((role: any) => !role.name.startsWith('module_'));
            const modulesFiltered = rolesCtx.roles.filter((role: any) => role.name.startsWith('module_'));

            const items: any = features.map((feature:any) => {
                if (feature.showSideBar && feature.enabled && isAuthenticated) {
                    const isDisabled = rolesFiltered.every((role: any) => feature.role.includes(role.name));
                    const isHidden = modulesFiltered.every((role: any) => (feature.module as string[]).includes(role.name));

                    return ({
                        key: feature.link,
                        icon: feature.icon,
                        label: (isDisabled) ? feature.title : <NavLink to={feature.link}>{feature.title}</NavLink>,
                        disabled: isDisabled,
                        hidden: isHidden,
                    });
                }
                return null;
            }).filter((item:any) => item !== null); // Filter out null values


            if (isAuthenticated && rolesFiltered.some((role: any) => ['nsadmin_home'].includes(role.name))) {
                //adds the Namespace Admin menu depending on the user role
                items.push(
                    { label: <Divider className='.ant-menu-item' orientation="left"><h3>Namespace Management</h3></Divider>, key: 'sideNM' },
                    // { label: <NavLink to="/nsadmin">My Namespaces</NavLink>, key: '/nsadmin', icon: <BankOutlined /> },
                );
            }
            if (isAuthenticated && rolesFiltered.some((role: any) => ["nsadmin_packages"].includes(role.name))) {
                items.push(
                    { label: <NavLink to="/nsadmin/packages">My Packages</NavLink>, key: '/nsadmin/packages', icon: <AppstoreOutlined /> },
                );
            }

            if (isAuthenticated && rolesFiltered.some((role: any) => ["nsadmin_users"].includes(role.name))) {
                items.push(
                    { label: <NavLink to="/nsadmin/users">My Users</NavLink>, key: '/nsadmin/users', icon: <UsergroupAddOutlined /> },
                );
            }

            if (isAuthenticated && rolesFiltered.some((role: any) => ["nsadmin_service_accounts"].includes(role.name))) {
                items.push(
                    { label: <NavLink to="/nsadmin/service-accounts">My Service Accounts</NavLink>, key: '5', icon: <ApiOutlined /> },
                );
            }

            setActiveMenuItems(items);
        }
    }, [rolesCtx, isAuthenticated, features]);

    return activeMenuItems;
}