import { Badge, Button, Flex } from "antd";
import { useContext, useState } from "react";

import CodeMirror from "@uiw/react-codemirror";
import { EnvContext } from "../../contexts";
import { SecureWithLogin } from "../../utils/secure";
import { callBackEnd } from "../../utils/backEndUtils";
import { json } from "@codemirror/lang-json";

interface TryItDeveloperProps {
    requestCode: any;
    responseCode: any;
    setRequestCode: (code: any) => void;
    setResponseCode: (code: any) => void;
    resetForm: () => void;
    defaultRequestCode: any;
}

const TryItDeveloperV2: React.FC<TryItDeveloperProps> = ({ requestCode, setRequestCode, responseCode, setResponseCode }) => {
    const env: string = useContext(EnvContext);
    const [jsonValidRibbon, setJsonValidRibbon] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);


    const [code, setCode] = useState<string>(`{
  "options": {
    "threshold": 85,
    "metaScore": false,
    "dobPrecision": 0,
    "cityProximity": 50,
    "dobPolicy": {
      "value": "required"
    },
    "genderPolicy": {
      "value": "required"
    },
    "countryPolicy": {
      "value": "required"
    },
    "cityPolicy": {
      "value": "required"
    }
  },
  "records": [
    {
      "object": "John Doe",
      "metadata": {
        "dobs": [
          "1970-01-01"
        ],
        "gender": "male",
        "places": [
          {
            "country": "US",
            "city": "New York City"
          }
        ]
      }
    }
  ]
}`);

    const handleOnChangeCode = (value: string) => {
        console.log("🚀 ~ handleOnChangeCode ~ value:", value);
        setCode(value);
        setJsonValidRibbon(isValidJSON(value));
        setRequestCode(JSON.parse(value));
    }

    const isValidJSON = (txt: string) => {
        if (txt.length > 0) {
            try {
                JSON.parse(txt);
                return true;
            } catch {
                return false;
            }
        }
        else {
            return false;
        }
    }

    const handleForm = async () => {
        setLoading(true);

        //Call the backend
        const targetEnv: string = (env === 'DEV') ? "DEV" : "SANDBOX";
        callBackEnd(targetEnv, 'POST', `api/v1/jetscan/screen/sanctions`, code)
            .then((response: any) => {

                setResponseCode(response);
                setLoading(false);
            })
            .catch((error: any) => {
                setLoading(false);
                setResponseCode(error);
            })
    }
    const handleTouchStart = (event: TouchEvent) => {
        // Your touch start logic here
        console.log("Touch start event:", event);
    };
    
    document.addEventListener('touchstart', handleTouchStart, { passive: true });

    return (

        <SecureWithLogin role={['portal_doc', 'js_screen']} callbackPath="/tryIt">
                <Badge.Ribbon text={jsonValidRibbon ? "Valid JSON" : "Invalid JSON"} color={jsonValidRibbon ? "green" : "red"}>
                    <CodeMirror
                        value={JSON.stringify(requestCode, null, 2)}
                        minHeight='300px'
                        style={{ border: jsonValidRibbon ? '2px solid rgb(240, 240, 240)' : '2px solid red', borderRadius: '2px' }}
                        onChange={handleOnChangeCode}
                        extensions={[json()]}
                    />
                </Badge.Ribbon>

                <Flex justify="end">
                    <Button type="primary" disabled={!jsonValidRibbon || loading} style={{ marginTop: "20px" }} onClick={handleForm}>
                        Send query to the API
                    </Button>
                </Flex>
        </SecureWithLogin >
    )
}
export default TryItDeveloperV2;