import '../../assets/scss/Sidebar.scss';

import { Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import useActiveMenuItems from '../../hooks/useActiveMenuItems';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

export default function Sidebar(props: any) {
    const [collapsed, setCollapsed] = useState(props.barCollapsed);
    const location = useLocation();
    const { pathname } = location;
    // const [menuItems, setMenuItems] = useState<MenuProps['items']>([]);

    const menuItems: any = useActiveMenuItems();

    //handles the collapse of the sidebar
    const handleCollapse = (value: boolean) => {
        setCollapsed(value);
        props.handleBarCollapse();
    }

    return (
        <>
            {menuItems &&
                <Sider
                    width={250}
                    style={{
                        overflow: "auto",
                        height: 'calc(100vh - 75px)',
                        position: "sticky",
                        top: 75,
                        left: 0,
                        scrollbarWidth: 'thin',
                    }}
                    theme="light"
                    collapsible
                    collapsed={collapsed}
                    onCollapse={handleCollapse}
                    className={collapsed ? "collapsed" : "extended"} >
                    <Menu
                        defaultSelectedKeys={[pathname]}
                        selectedKeys={[pathname]}
                        theme="light"
                        mode="vertical"
                        items={menuItems} />
                </Sider>
            }
        </>

    )
}