import { ReactNode, useContext, useEffect, useState } from "react";

import { callBackEnd } from "../utils/backEndUtils";
import { createContext } from "react";
import { useOidc as getOidc, } from '@axa-fr/react-oidc';

export const RolesContext = createContext<RolesProviderType | null>(null);

interface RolesProviderProps {
    children: ReactNode;
    roles?: RolesType;
    loading?: boolean;
}

const RolesContextProvider: React.FC<RolesProviderProps> = ({ children }) => {

    const [roles, setRoles] = useState<RolesType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const { isAuthenticated } = getOidc('config');

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await callBackEnd('portal', 'get', `me/roles`);
                setRoles(response);
            } catch (error) {
                console.error('Failed to fetch roles:', error);
                throw error;
            } finally {
                setLoading(false);
            }
        };
        if (isAuthenticated){
            fetchRoles().catch((e: any) => console.log(e));
        }else{
            setRoles(null);
            setLoading(false);
        }
    }, [isAuthenticated]);

    return (
        <RolesContext.Provider value={{ roles, loading }}>
            {children}
        </RolesContext.Provider>
    )
}

export function useRoles() {
    return useContext(RolesContext);
}

export default RolesContextProvider;