import { Button, Card, Checkbox, Col, DatePicker, Divider, Form, Input, Radio, Row, Select, Slider, Space, Typography } from 'antd';
import { CopyOutlined, EditOutlined } from '@ant-design/icons';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { DefaultOptionType } from 'antd/es/select';
import { EnvContext } from '../../contexts/EnvironmentContext';
import MotionBox from '../Layout/Animated/MotionBox';
import ReactJson from '@microlink/react-json-view';
import { SecureWithLogin } from '../../utils/secure';
import TryItDeveloperV2 from './TryItDeveloperV2';
import TryItResultsStats from './stats/TryItResultsStats';
import TryItResultsTable from './tables/TryItResultsTable';
import { callBackEnd } from '../../utils/backEndUtils';
import dayjs from 'dayjs';
import { getCountryListAlpha2 } from '../../utils/geoInfos';
import { useLocation } from 'react-router-dom';

// import { set } from 'mobx';

interface TryItFormV2Props {
    requestCode: any;
    setRequestCode: (code: any) => void;
    responseCode: any;
    setResponseCode: (code: any) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    resetForm: () => void; // Add resetForm to the props
    defaultRequestCode: any;
}

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}



const TryItFormV2 = ({ requestCode, setRequestCode, responseCode, setResponseCode, loading,setLoading, defaultRequestCode, resetForm }: TryItFormV2Props) => {
    const [editMode, setEditMode] = useState<boolean>(false);
    const env: string = useContext(EnvContext);
    // const { readString } = usePapaParse();
    const [form] = Form.useForm();
    const query = useQuery(); // Get query parameters
    // setRequestCode({ options: {}, records: [{ object: query.get('name') }] }); // Reset the name field

    const [isFormSubmitable, setIsFormSubmitable] = useState<boolean>(false);
    
    const [dobType, setDobType] = useState<string>('full');
    const [showCityField, setShowCityField] = useState<boolean>(false);
    const [showGenderField, setShowGenderField] = useState<boolean>(false);
    const [showGenderBox, setShowGenderBox] = useState<boolean>(false);
    const [showCountryBox, setShowCountryBox] = useState<boolean>(false);
    const [showDobfield, setShowDobField] = useState<boolean>(false);
    const [inputMethod, setInputMethod] = useState<string>('single');
    const [isMetaMatching, setIsMetaMatching] = useState<boolean>(true);
    const [cityFieldEnabled, setCityFieldEnabled] = useState<boolean>(false);
    const [showDobBox, setShowDobBox] = useState<boolean>(false);

    // const handleJsonChange = (e: any) => {
    //     setRequestCode(e.updated_src);
    // };
    
    const buildRequestCode = async (values: any) => {

        console.log("values", values)

        // console.log(values.optionCityPolicyLevel)
        //Build the request code
        const rcCopy: any = { ...requestCode };

        //Options
        rcCopy.options = {
            recordType: values?.recordType || 'unknown',
            threshold: values?.optionThreshold,
            // metaScore: values?.optionHolisticMatching === "metaScore",
            dobPrecision: values?.optionDobPrecision,
            cityProximity: values?.optionCityProximity || 50,
        }
        if(values?.metadataDob){
            rcCopy.options.dobPolicy = {
                value: (values?.requiredMatches?.includes('dob')) ? "required" : "optional",
            }
        }

        if(values?.metadataGender){
            rcCopy.options.genderPolicy = {
                value: (values?.requiredMatches?.includes('gender') ? "required" : "optional"),
            }
        }

        if(values?.metadataPlacesCountry){
            rcCopy.options.countryPolicy = {
                value: (values?.requiredMatches?.includes('country') ? "required" : "optional"),
            }
        }

        if(values?.metadataPlacesCity && values?.metadataPlacesCountry){
            rcCopy.options.cityPolicy = {
                value: (values?.requiredMatches?.includes('city') ? "required" : "optional"),
            }
        }

        //records
        rcCopy.records = [];

        if (inputMethod === 'single') {


            rcCopy.records.push({
                ...(values?.name && { object: values?.name?.trim() }),
            });


            //add metadata if exists
            if (values.metadataGender || values.metadataDob || values.metadataPlacesCountry || values.metadataPlacesCity) {
                rcCopy.records[0].metadata = {};

                if (values.metadataDob) {
                    //format the date of birth if exists
                    let formattedDateOfBirth: string = '';
                    if (values.metadataDobType === 'full') {
                        formattedDateOfBirth = dayjs(values.metadataDob).format('YYYY-MM-DD');
                    }
                    else {
                        formattedDateOfBirth = dayjs(values.metadataDob).format('YYYY');
                    }

                    rcCopy.records[0].metadata.dobs = [formattedDateOfBirth];
                }
                if (values.metadataGender) {
                    rcCopy.records[0].metadata.gender = values.metadataGender;
                }
                if (values.metadataPlacesCountry) {
                    rcCopy.records[0].metadata.places = [{
                        country: values.metadataPlacesCountry,
                        ...(values.metadataPlacesCity && { city: values.metadataPlacesCity })
                    }];
                }
            }
        // } else if (values.inputMethod === 'csv' && values?.csvFile?.fileList.length > 0) {
        //     const csvFile: any = values?.csvFile?.fileList[0].originFileObj;
        //     rcCopy.records = await returnCsvData(csvFile);
        }

        return rcCopy;
    }

    const handleFormReset = () => {
        form.resetFields();
        resetForm();
        setRequestCode({ options: {}, records: [] }); // Reset the name field
        setResponseCode(undefined);
        setIsFormSubmitable(false);
        setDobType('full');
        setShowCityField(false);
        setLoading(false);
        setInputMethod('single');
        setIsMetaMatching(true);
        setEditMode(false);
    }

    const handleForm = async (values: any) => {
        setLoading(true);

        const rc: any = await buildRequestCode(values);
        setRequestCode(rc);

        //Call the backend
        const targetEnv: string = (env === 'DEV') ? "DEV" : "SANDBOX";
        callBackEnd(targetEnv, 'POST', `api/v1/jetscan/screen/sanctions`, JSON.stringify(rc))
            .then((response: any) => {

                setResponseCode(response);
                setLoading(false);
            })
            .catch((error: any) => {
                console.log(error)
            })
    }

    // console.log(requestCode)
    const handleFieldChange = (changedFields: any, allFields: any) => {
        if (changedFields[0]?.name[0] === 'name') {
            if (changedFields[0]?.value?.length) {
                setIsFormSubmitable(true);
            }
            else {
                setIsFormSubmitable(false);
            }
        }
        if (changedFields[0]?.name[0] === 'recordType') {
            if (changedFields[0]?.value === 'individual') {
                setShowGenderField(true);
                setShowDobField(true);
            }
            else{
                setShowGenderField(false);
                setShowDobField(false);
            }
        }

        if (changedFields[0]?.name[0] === 'metadataDob') {
            setShowDobBox(true);
        }
        if (changedFields[0]?.name[0] === 'metadataGender') {
            setShowGenderBox(true);
        }
        if (changedFields[0]?.name[0] === 'metadataPlacesCountry') {
            setShowCountryBox(true);
        }
        if (changedFields[0]?.name[0] === 'metadataDobType') {
            setDobType(changedFields[0]?.value);
        }
        if (changedFields[0]?.name[0] === 'metadataPlacesCountry') {
            if (changedFields[0]?.value?.length) {
                setShowCityField(true);
            }
            else {
                setShowCityField(false);
            }
        }
        if (changedFields[0]?.name[0] === 'inputMethod') {
            setInputMethod(changedFields[0]?.value);
        }
        if (changedFields[0]?.name[0] === 'csvFile') {
            setIsFormSubmitable(true);
        }
        if (changedFields[0]?.name[0] === 'optionHolisticMatching') {
            setIsMetaMatching(changedFields[0]?.value === "metaMaching");
            if(changedFields[0]?.value === "metaMaching"){
                form.setFieldsValue({
                    optionDobPolicyValue: 'required',
                    optionGenderPolicyValue: 'required',
                    optionCountryPolicyValue: 'required',
                    optionCityPolicyValue: 'required',
                });
            }
            else{
                form.setFieldsValue({
                    optionDobPolicyValue: 'optional',
                    optionGenderPolicyValue: 'optional',
                    optionCountryPolicyValue: 'optional',
                    optionCityPolicyValue: 'optional',
                });
            }
        }
        if (changedFields[0]?.name[0] === 'requiredMatches') {
            if(changedFields[0]?.value.includes('dob')){
                setShowDobBox(true);
            }
            if(changedFields[0]?.value.includes('gender')){
                setShowGenderBox(true);
            }
            if (changedFields[0]?.value.includes('country')) {
                setCityFieldEnabled(true);
            } else {
                setCityFieldEnabled(false);
                    if(changedFields[0]?.value.includes('city')){
                        form.setFieldsValue({
                            requiredMatches: changedFields[0]?.value.filter((v: string) => v !== 'city')
                    });
                }
            }
        }
    }

    const handleValuesChange = useCallback(async (changedValues: any, allValues: any) => {
        const request: any = await buildRequestCode(allValues);
        setRequestCode(request);
    }, []);

    const handleEditModeSwitch = () => {
        setEditMode(true);
        console.log("editMode", editMode)
    }

    // Function to prefill form from query parameters
    useEffect(() => {
        if(query.size !== 0){
            const prefillData = {
                name: query.get('object'),
                recordType: query.get('recordType') || 'unknown',
                metadataGender: query.get('metadataGender'),
                metadataDob: query.get('metadataDob'),
                metadataPlacesCountry: query.get('metadataPlacesCountry') ? query.get('metadataPlacesCountry') : undefined,
                metadataPlacesCity: query.get('metadataPlacesCity') ? query.get('metadataPlacesCity') : undefined,
                optionThreshold: Number(query.get('optionThreshold')) || 85,
                optionHolisticMatching: query.get('optionHolisticMatching'),
                optionDobPrecision: Number(query.get('optionDobPrecision')) || 0,
                optionCityProximity: Number(query.get('optionCityProximity')) || 50,
                optionDobPolicyLevel: Number(query.get('optionDobPolicyLevel')),
                // Add other fields as needed
            };

            form.setFieldsValue(prefillData);
            if (query.get('metadataPlacesCountry') ) {
                setShowCityField(true);
            }
            if (query.get('recordType') === 'individual') {
                setShowGenderField(true);
                setShowDobField(true);
            }
            if (query.get('metadataDob')) {
                setShowDobBox(true);
            }
            if (query.get('metadataGender')) {
                setShowGenderBox(true);
            }
            if (query.get('metadataPlacesCountry')) {
                setShowCountryBox(true);
            }
            
            setIsFormSubmitable(true);
            handleValuesChange(prefillData, prefillData);
        }
    }, [form,query,handleValuesChange]);

    return (
        <SecureWithLogin role={['portal_doc', 'js_screen']} callbackPath="/tryIt">
            <MotionBox>
                <Row gutter={[24, 24]} >
                    {/* <Divider orientation='left'>Screening Profile</Divider> */}
                    <Col xs={24} xl={12} xxl={12}>
                        <Card title="API Form" style={{height:'100%'}} extra={<Button type="default" name="resetButton" onClick={handleFormReset}>Reset</Button>}>
                            <Form
                                disabled={editMode}
                                layout='vertical'
                                labelWrap
                                form={form}
                                onFinish={handleForm}
                                onValuesChange={handleValuesChange}
                                onFieldsChange={handleFieldChange}
                            >
                                {/* <Flex><Typography.Title level={3}>Title?</Typography.Title></Flex> */}
                                <Form.Item
                                    colon={false}
                                    label="Name to screen"
                                    labelCol={{sm:{ span: 24, offset: 0 }}}
                                    tooltip="Please enter a first name and a last name as entering just one will return too many results"
                                    name='name'
                                    // initialValue={requestCode?.records[0]?.object || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a first name and a last name',
                                        }
                                    ]}
                                >
                                    <Input placeholder='Full Name' type="text" value={requestCode?.records[0]?.object || ''} />
                                </Form.Item>

                                <Form.Item
                                    colon={false}
                                    label="Type"
                                    name='recordType'
                                    labelCol={{sm:{ span: 24, offset: 0 }, md:{ span: 6, offset: 0 }}}
                                    initialValue={requestCode?.recordType ? requestCode.recordType.toString() : 'unknown'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a type',
                                        }
                                    ]}
                                >
                                    <Radio.Group>
                                        <Radio value='unknown' name="radioUknown">Generic</Radio>
                                        <Radio value='individual' name="radioIndividual">Individual</Radio>
                                        <Radio value='entity' name="radioEntity">Entity</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {showGenderField &&
                                <Form.Item
                                    colon={false}
                                    label="Gender"
                                    name="metadataGender"
                                    initialValue={requestCode.metadataGender || undefined}

                                >
                                    <Select allowClear options={[
                                        { label: "Male", value: "male" },
                                        { label: "Female", value: "female" },
                                        { label: "Other", value: "other" }
                                    ]} />
                                </Form.Item>
                                }
                                {showDobfield &&
                                <>
                                <Form.Item label="Date of birth" colon={false}>
                                    <Form.Item
                                        colon={false}
                                        name='metadataDobType'
                                        initialValue={requestCode.metadataDobType || 'full'}
                                        style={{ display: 'inline-block', marginRight: '40px' }}
                                    >
                                        <Radio.Group>
                                            <Radio value="full">Full date</Radio>
                                            <Radio value="year">Year only</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        colon={false}
                                        name='metadataDob'
                                        initialValue={requestCode.metadataDob || null}
                                        style={{ display: 'inline-block' }}
                                    >
                                        {dobType === 'full' ?
                                            <DatePicker maxDate={dayjs()} />
                                            :
                                            <DatePicker picker="year" maxDate={dayjs()}/>
                                        }
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    label="Dob Precision (years)"
                                    tooltip="In case of year matching, the accepted difference (in years)"
                                    name='optionDobPrecision'
                                    initialValue={requestCode.options.dobPrecision || 0}
                                    hidden={!showDobfield}
                                    // wrapperCol={{ span: 12 }}
                                >
                                    <Slider
                                        min={0}
                                        max={10}
                                        marks={{
                                            0: '0',
                                            2: '2',
                                            4: '4',
                                            6: '6',
                                            8: '8',
                                            10: '10'
                                        }}
                                        value={requestCode.options.dobPrecision}
                                    />
                                </Form.Item></>
}

                                <Form.Item
                                    colon={false}
                                    label="Country"
                                    name='metadataPlacesCountry'
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Select a country"
                                        optionFilterProp="label"
                                        options={getCountryListAlpha2() as DefaultOptionType[]} />
                                </Form.Item>

                                {showCityField &&
                                <>
                                <Form.Item
                                    colon={false}
                                    label="City"
                                    tooltip="City will be ignored if a country isn't set"
                                    name='metadataPlacesCity'
                                >
                                    <Input placeholder="Enter the city" />
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    label="City Proximity (km)"
                                    name='optionCityProximity'
                                    tooltip="Accepted distance (km) between two mismatched cities."
                                    initialValue={50}
                                    // wrapperCol={{ span: 12 }}
                                >
                                    <Slider
                                        value={50}
                                        min={0}
                                        max={500}
                                        marks={{
                                            0: '0',
                                            100: '100',
                                            200: '200',
                                            300: '300',
                                            400: '400',
                                            500: '500',
                                        }}
                                    />
                                </Form.Item>
                                </>
                                }
                                <Divider />
                                <Form.Item
                                        colon={false}
                                        label='Required&nbsp;Matches'
                                        labelCol={{sm:{ span: 24, offset: 0 }, md:{ span: 6, offset: 0 }}}
                                        name='requiredMatches'
                                        initialValue={'full'}
                                    >
                                    <Checkbox.Group>
                                        <Checkbox value="dob" disabled={!showDobBox} >Date of Birth</Checkbox>
                                        <Checkbox value="gender" disabled={!showGenderBox} >Gender</Checkbox>
                                        <Checkbox value="country" disabled={!showCountryBox}>Country</Checkbox>
                                        <Checkbox value="city" disabled={!cityFieldEnabled}>City</Checkbox>
                                    </Checkbox.Group>
                                    </Form.Item>
                                <Divider />

                                <Form.Item
                                    colon={false}
                                    label="Threshold"
                                    name='optionThreshold'
                                    tooltip="Name matching threshold. When the threshold value is between 50 and 84 matching is more broad, and some hits returned in this mode, are not retrieved otherwise"
                                    initialValue={85}
                                    // wrapperCol={{ span: 8 }}
                                >
                                    <Slider
                                    value={85}
                                    min={85}
                                    max={100}
                                        marks={{
                                            85: '85',
                                            90: '90',
                                            95: '95',
                                            100: '100'
                                        }}
                                    />
                                </Form.Item>
                                {isFormSubmitable && requestCode.records.length>0 &&
                                <Form.Item name="sendRequest">
                                    <Space size="middle">
                                        <Button type="primary" htmlType="submit" loading={loading}>
                                            Send Request
                                        </Button>
                                    </Space>
                                </Form.Item>
                                }
                            </Form>
                        </Card>
                    </Col>

                    <Col xs={24} xl={12} xxl={12}>
                        <Card title={"API Request"} style={{height:'100%'}} extra={
    <Space size="middle">
        <Typography.Text type="secondary" copyable={{
        icon: [<span><Button onClick={()=>{ requestCode && navigator.clipboard.writeText(JSON.stringify(requestCode));}}><CopyOutlined />Copy to Clipboard</Button></span>,''],
        tooltips: ['Copy to Clipboard', 'Copied!'],
      }}></Typography.Text>
        
    {!editMode && <Button onClick={handleEditModeSwitch}><EditOutlined />Edit</Button>}
    </Space>
}>
                            {editMode ?
                            <TryItDeveloperV2 requestCode={requestCode} setRequestCode={setRequestCode} responseCode={responseCode} setResponseCode={setResponseCode} resetForm={resetForm} defaultRequestCode={defaultRequestCode} /> :
                            <ReactJson src={requestCode} indentWidth={2} enableClipboard={false} />
                            }
                        </Card>
                    </Col>
                    {(responseCode && !loading) && <Col xs={24}><TryItResultsStats responseCode={responseCode} /></Col>}
                            {(responseCode && !loading) && <>{responseCode.results.map((results: any, index: number) => {
                                return (
                                    <Col key={index} md={24} sm={24}>
                                        <Card>
                                        <Divider orientation='left'>API Matches found for {results.object}</Divider>
                                        <TryItResultsTable matches={results.matches} />
                                        </Card>
                                    </Col>
                                )
                            })}
                        </>
                    }

                    {/* {(responseCode && !loading) &&
                    <>
                        <Col md={24} sm={24}>
                            <Collapse
                                items={[{ key: '1', label: 'JSON API Response', children: <ReactJson src={responseCode} indentWidth={2} /> }]}
                            />
                        </Col>
                        <Col md={24} sm={24}>
                            <Divider orientation="left">API Response</Divider>
                            <Collapse
                                defaultActiveKey={['1', '2']}
                                items={[
                                    {
                                        key: '1', label: 'Matches Table', children:
                                            <>
                                                {responseCode.results.map((results: any, index: number) => {
                                                    return (
                                                        <TryItResultsTable key={index} matches={results.matches} />
                                                    )
                                                })}
                                            </>
                                    },
                                    { key: '2', label: 'API Response', children: <ReactJson src={requestCode} onEdit={handleJsonChange} onAdd={handleJsonChange} onDelete={handleJsonChange} /> }
                                    // { key: '2', label: 'API Response', children: <ReactJson src={responseCode} indentWidth={2} /> }
                                ]}
                            />
                        </Col>
                    </>
                }
                    {loading &&
                        <Col md={24} sm={24}>
                            <LoadingSkeleton />
                        </Col>
                    } */}
                </Row>
            </MotionBox>
        </SecureWithLogin >
    )
}

export default TryItFormV2