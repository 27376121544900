import { Button, Flex, Table, TableColumnsType, Tag, Tooltip } from 'antd';

import { Link } from 'react-router-dom';
import MotionBox from '../../Layout/Animated/MotionBox'
import { SecureWithLogin } from '../../../utils/secure'

export default function TryItResultsTable(props: { matches: any }) {
    const { matches } = props;

    interface TryItResultsTableData {
        match: string,
        profileId: string,
        score: number,
        type: string,
        screened: string,
        tags: string[],
        categories: string[],
        sanctions: string[],
    }

    const handleExportToCSV = () => {
        const csv: any = matches.map((m: any) => {
            return {
                Match: m.match,
                Score: m.score,
                Type: m.type,
                Sanctions: m.profileSummary.sanctions.join(","),
                ProfileId: m.profileId,
                Tags: m.profileSummary.tags.join(","),
                Categories: m.profileSummary.categories.join(",")
            }

        });
        const now = new Date();
        const datetime = now.toISOString().replace(/:/g, '-');

        const header = Object.keys(csv[0]);
        const csvData = csv.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName])).join('\t'));
        const csvString = [header.join('\t'), ...csvData].join('\r\n');

        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `neterium_screening_${datetime}.csv`;
        a.click();
    }

    const columns: TableColumnsType<TryItResultsTableData> = [
        {
            title: 'Match',
            dataIndex: ['match'],
        },
        {
            title: "Score",
            dataIndex: "score",
            sorter: (a: any, b: any) => a.score - b.score,
            sortDirections: ['descend', 'ascend'],
            render: (score: number) => {
                return <Tag color="green">{score}</Tag>
            }
        },
        {
            title: "Type",
            dataIndex: "type"
        },
        {
            title: "Sanctions",
            dataIndex: ['profileSummary', 'sanctions'],
            render: (sanctions: string[]) => sanctions.map((s: string) => {
                return <Tag color="blue" key={s}>{s}</Tag>
            })
        },
        {
            title: "Profile ID",
            dataIndex: "profileId",
            render: (text: string) => <Tooltip placement="top" title={"View detailed profile in a new tab"}><Link to={`/profileDetails/${text}`} target="_blank" rel="noopener noreferrer">{text}</Link></Tooltip>,
        },
        {
            title: "Tags",
            dataIndex: ['profileSummary', 'tags'],
            render: (tags: string[]) => tags.map((t: string) => {
                return <Tag color="red" key={t}>{t}</Tag>
            })
        },
        {
            title: "Categories",
            dataIndex: ['profileSummary', 'categories'],
            render: (categories: string[]) => categories.map((c: string) => {
                return <Tag color="blue" key={c}>{c}</Tag>
            })
        }

    ]

    return (
        <SecureWithLogin role={['portal_doc', 'js_screen']} callbackPath="/tryIt">
            {matches &&
                <MotionBox>
                    <Table
                        columns={columns}
                        dataSource={matches}
                        pagination={false}
                        scroll={{ x: 'max-content' }}
                        rowKey={(record: any) => record.matchId}
                    />
                    {matches.length &&
                        <Flex justify="right">
                            <Button type="default" style={{ marginTop: '1rem' }} onClick={handleExportToCSV}>Export matches to CSV</Button>
                        </Flex>
                    }

                </MotionBox>
            }
        </SecureWithLogin>

    )
}
